export default {
  api: {
    operationSucceed: '操作完了',
    operationFailed: '操作失敗',
    errorTip: 'エラー',
    errorMessage: 'システム異常、操作に失敗しました!',
    timeoutMessage: 'ログインがタイムアウトしました。再度ログインしてください!',
    apiTimeoutMessage:
      'インターフェイスリクエストがタイムアウトしました。ページを更新して、もう一度お試しください!',
    apiRequestFailed: 'リクエストにエラーが発生しました。後でもう一度お試しください。',
    networkException: 'ネットワーク異常',
    networkExceptionMsg: 'ネットワーク異常。ネットワークの接続を確認してください!',

    errMsg401: 'ユーザーに権限がありません（トークン、ユーザー名、パスワードエラー）!',
    errMsg403: '権限はありますが、アクセスが拒否されています!',
    errMsg404: 'ネットワークリクエストエラー。リソースが見つかりません!',
    errMsg405: 'ネットワークリクエストエラー。リクエスト方法が許可されていません！',
    errMsg408: 'ネットワークリクエストがタイムアウトしました!',
    errMsg500: 'サーバーエラー、管理者に連絡してください!',
    errMsg501: 'ネットワークがありません!',
    errMsg502: 'ネットワークエラー!',
    errMsg503: 'サーバー過負荷またはメンテナンスのため、現在利用できません!',
    errMsg504: '接続タイムアウト!',
    errMsg505: 'httpバージョンはこのリクエストに対応していません!',
  },
  exception: {
    backLogin: 'ログインに戻る',
    backHome: 'トップページに戻る',
    subTitle403: '申し訳ございません。このページにアクセスする権限がありません。',
    subTitle404: '申し訳ございません。訪問しているページは存在しません。',
    subTitle500: '申し訳ございません。サーバーからエラーが報告されました。',
    noDataTitle: 'このページのデータがありません',
    networkErrorTitle: 'ネットワークエラー',
    networkErrorSubTitle: '申し訳ございません。接続が切れました。ネット状態を確認してください！',
  },
  app: {
    title: 'ホームページ',
    description: 'エースモール、ゲームチャージ、Dmenチャージ、コインチャージ',
    tip: 'ヒント',
    copyright: 'copyright©2021 Beijing Ace Entertainment Technology Co.,Ltd.',
    icp: '京ICP备20009004号',
    address: '所在地：北京市朝陽区北三環東路28号',
    account: 'アカウント',
    changePwd: 'パスワード変更',
    chargeRecord: 'チャージ履歴',
    helpCenter: 'ヘルプ',
    chargeNow: 'チャージ',
  },
  login: {
    email: 'メールアドレス',
    verifyCode: '認証コード',
    password: 'パスワード',
    passwordHolder: 'パスワード（8～30桁。数字、英文字を入力可）',
    loginButton: 'ログイン',
    freeAccount: '無料登録',
    freeAccountCreate: '無料でアカウントを作成',
    forgetPassword: 'パスワードを忘れた方はこちら',
    findPassword: 'パスワード再設定',
    findEmailSent:
      'お客様のメールアドレスにパスワード再設定メールが送信されました。メール内のリンクをクリックしてパスワードを再設定してください。',
    forgetFormTitle: 'パスワード再設定',
    oldPassword: '旧パスワード',
    newPassword: '新パスワード',
    repeatPassword: 'パスワードをもう一度入力してください',
    policyCheck: '同意する場合はチェックを入れてください',
    policy: '《Ace Entertainment プライバシーポリシー》',
    hadAccount: 'すでにアカウントがある方',
    backSignIn: '返回登录',

    logoutButton: 'ログアウト',
    registerButton: '登録',

    otherSignIn: '他のログイン方法',

    // notify
    loginSucceed: 'ログインしました',
    logoutSucceed: 'ログアウトしました',
    registerSucceed: '登録完了',
    changePwdSucceed: 'パスワードを変更しました',
    resetPwdSucceed: 'パスワードを再設定しました',

    // ValidateMsg
    emailValidateMsg: '正しいメールアドレスを入力してください',
    passwordValidateMsg: '8～30桁の数字と英文字の組み合わせを入力してください',
    verifySentMsg: '認証コードを送りました',
    verifyValidateMsg: '6桁の認証コードを入力してください',
    policyValidateMsg: '登録する場合はチェックを入れてください',
    diffPwdValidateMsg: 'パスワードが一致しません',
  },
  help: {
    helpModalTitle: 'ヘルプ',
    helpTipsTitle: 'ヒント',
    helpTips_1:
      '本プラットホームでチャージすると獲得できる仮想アイテムは、“D-MEN：The Defenders”でのみ適用されます。',
    helpTips_2:
      'ご注文の際には、購入する商品の名前、価格、通貨、数量、仕様、詳細、注文方法、配送先、連絡先、支払方法等の情報をご確認ください。損失を防ぐため、個人情報を他人に教えないようお願いします。',
    serviceTitle: 'サービスの説明',
    service_1:
      '本サービスは、“D-MEN: The Defenders”アプリに連携しているユーザーのみ利用可能です。連携していないユーザーがサービスを利用するには、先にゲーム内で連携を行う必要があります。',
    service_2:
      '重要：アカウント番号を入力した後、表示されたアカウント情報をよく確認してから次のステップに進んでください！（アカウントを間違えた場合でも返金できませんので、ご注意ください！）',
    service_3:
      '支払い完了後、対応する仮想アイテムがシステムよりお客様のアカウントに送信されますので、ゲーム内でご確認ください。支払い後30分たってもアイテムが送信されてない場合、次の方法従いカスタマーサービスまでご連絡ください。',
    contactTitle: 'カスタマーサービスリスト',
    contactEmail: 'カスタマーサービスメールアドレス',
    contactFacebook: 'Facebookリンク',
    contactDiscord: 'Discordリンク',
  },
  charge: {
    title: '商品リスト',
    description: 'エースモール、ゲームチャージ、Dmenチャージ、コインチャージ',
    coinSelect: '通貨選択',
    merchandiseList: 'ゲーム商品リスト',
    buyNow: '今すぐ購入',
    quickLimit: '期間限定セール',
    buyLimit: '各キャラクター最大{limit}個まで購入可能',
    chooseRole: 'キャラクターを選択してください',
    server: 'サーバー',
    serverIdHolder: 'サーバーIDを入力してください',
    roleID: 'キャラクターID',
    roleIDHolder: 'キャラクターIDを入力してください',
    searchResult: '検索結果',
    addRole: 'キャラクター追加',
    roleInfo: 'キャラクター情報',
    roleLevel: 'レベル{level}',
    gameName: 'ゲーム名',
    action: '操作',
    confirmDel: '削除しますか?',
    paymentWay: '支払い方法',
    paymentAmount: '支払い金額',
    roleLimit: 'このキャラクターの残り購入数: ',
    payNow: '今すぐ支払う',
    payJumpTips: 'サードパーティの決済プラットフォームへ移動します。少々お待ちください...',
    paid: '支払い完了',
  },
  orders: {
    title: '注文履歴',
    description: '注文履歴',
    ordersTitle: '注文履歴',
    searchDate: '日付を検索',
    selectGame: 'ゲームを選択',
    allGame: '全てのゲーム',
    orderNo: '注文番号',
    gameName: 'ゲーム名',
    merchandiseName: '商品名',
    merchandiseDetail: '商品详情',
    orderAmount: '注文金額',
    createAt: '作成時間',
    orderStatus: 'ステータス',
    statusGoing: '支払い中',
    statusSucceed: '支払い完了',
    statusUnknown: '支払い結果を表示するには充電レコードに移動してください',
    statusFailed: '支払い失敗',
    action: '操作',
    viewDetail: '詳細表示',
    detailTitle: '注文詳細',
    backToOrders: '注文履歴に戻る',
    orderInfo: '注文情報',
    merchandiseInfo: '商品情報',
    price: '単価',
    quantity: '数量',
    discount: '割引',
    payResult: '支払い結果',
    viewOrder: '注文確認',
  },
  order: {
    title: '注文詳細',
    description: '注文詳細',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: '支払い結果',
    description: '支払い結果',
  },
};
